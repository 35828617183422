import { useState, useEffect } from "react"
import { headerHeight, footerHeight } from "../constants";


const NotFound = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
  }, []);

  const imgStyle = {
    opacity: isVisible ? 1 : 0,
    transition: "opacity 3s"
  }


  return (
    <div className='col-12' style={styles.main}>
      <div>
      <img style={imgStyle} src="icons/404.png" alt="Page not found =)" />
      </div>
      <div>
      404 Page not found
      </div>
    </div>
  )
}


const styles = {
  main: {
    height: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
    width: "100vw",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
  },
}


export default NotFound
