// constants
import { color, font } from "../../constants"


function Txt() {
  return(
    <div style={styles.txt}>

      <h2 style={styles.h2}>
        Moja wizja:
      </h2>
      <div style={styles.descr}>
        <i>
          <p>
            Przez budowanie (samo) świadomosci w klientach, partnerowanie im w rozwoju i zmianie, pomagam odzyskać siłę, wolność i spełnienie.
          </p>
          <p>
            Wierze, że człowiek świadomy siebie, swoich emocji, działań, ich przyczyn i skutków, to osoba ciągle rozwijająca się i pełna dobra, akceptacji, miłości i szacunku do życia, chce kreować swój wewnętrzny i zewnętrzny świat właśnie z tego poziomu i wspiera w tym innych.
          </p>
        </i>
      </div>

      <h2 style={styles.h2}>
        Wszystkie moje działania podejmowane są w oparciu o te wartości:
      </h2>
      <div style={styles.descr}>
        <i>
          <p>Uważność, świadomość, podążanie za klientem.</p>
          <p>Zaufanie i bezpieczeństwo.</p>
          <p>Partnerstwo i otwartość.</p>
          <p>Wykorzystuję wyłącznie informację popartą badaniami, udowodnioną, skuteczną i wartościową.</p>
        </i>
      </div>

    </div>
  )
}

function Img({mob=false}) {
  return(
    <img src={"/photos/myVision.jpg"} alt="Nature" style={mob ? styles.imgMob : styles.img} className="AboutVisionImg" />
  )
}


const ImgMob = () => <Img mob={true} />


function AboutVision() {
  return(
    <div>
    
      <div className="d-none d-lg-flex AboutVisionMain" style={styles.main}>
        <Img />
        <Txt />
      </div>

      <div className="d-lg-none AboutVisionMob" style={styles.mob}>
        <ImgMob />
        <Txt />
      </div>

    </div>
  )

}


const styles = {
  main: {
    backgroundColor: color.background.bright2, 
    marginTop: 160, 
    paddingBottom: "3rem"
  },
  img: {
    width: "15%", 
    height: "fit-content", 
    marginTop: 80,
    marginLeft: "5rem",
    borderRadius: 10,
  },
  txt: {
    margin: "80px 5vw 0",
  },
  h2: {
    paddingBottom: "1rem",
    color: color.font.secondary,
  },
  descr: {
    font: font.main2,
    fontSize: font.size.main,
    fontWeight: 380,
    padding: "0 6vw 1rem 0",
    color: color.font.secondary,
  },
  mob: {
    display: "bolck",
    textAlign: "center",
    backgroundColor: color.background.bright2, 
    marginTop: 40, 
    paddingTop: "1rem", 
    paddingBottom: "5rem"
  },
  imgMob: {
    width: "55%", 
    height: "fit-content", 
    marginTop: 80,
  },
}


export default AboutVision
