import { useState } from "react"

import Card from "react-bootstrap/Card"
import { Link } from "react-router-dom"
// constants
import { color } from "../../constants"


const services = [
  {title: "PROCESS COACHINGOWY", linkTo: "https://view.flodesk.com/pages/64674a748533b6ba4f709851", bgColor: "#e05858"},
  {title: "SESJA COACHINGOWA", linkTo: "https://view.flodesk.com/pages/645a156aee4ddcb4bc2d670e", bgColor: "#ebba59"},
  {title: "BEZPŁATNA SESJA WSTĘPNA", linkTo: "https://view.flodesk.com/pages/64674fa98533b6ba4f709853", bgColor: "#62a4ab"},
]


const CooperationService = ({title, linkTo, bgColor}) => {
  const [hovered, setHovered] = useState(false)

  const linkStyles = {
    color: hovered ? color.font.linkHover : color.font.link,
    transition: "color 0.3s",
  }

  return (
    <div>
      <Card style={{...styles.card, backgroundColor: `${bgColor}`}}>
        <Link 
          to={linkTo} 
          style={linkStyles}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {title}
        </Link>
      </Card>
    </div>
  )
}


const styles = {
  card: {
    minWidth: 300, 
    maxWidth: "20vw", 
    height: 150,
    margin: "20px 2vw", 
    backgroundColor: color.background.main,
    textAlign: "center",
    placeContent: "center",
  },
  a: {
    color: color.font.link,
  }
}


export { CooperationService, services }
