import { useEffect } from "react"

import CooperateBtn from "../Buttons/Cooperate"
import {Facebook, Instagram} from  "../Social/Icons"
import NewsLetterSubscription from "../Form/NewsLetterSubsciption/NewsLetterSubscription"

// constants
import { color, font, headerHeight, mobileBrowserHeader } from "../../constants"


function Info() {
  useEffect(() => {
    document.title = "Olena Melentieva - Kontakt"
  }, [])

  return(
    <div style={styles.content} className="ContactsInfo">
      <h1 style={styles.h1} className="ContactsH1">KONTAKT</h1>
      <div style={styles.contacts} className="Contacts">
        <p style={styles.header} className="ContactsHeader">Email:</p>
        <p style={styles.txtContent} className="ContactsTxtContent">olenamelentieva@gmail.com</p>
      </div>
      <CooperateBtn />
      <div style={styles.social} className="ContactsSocial">
        <Facebook />
        <span style={{width: 40}} />
        <Instagram />
      </div>
    </div>
  )
}


function Img() {
  return(
    <img src={"/photos/contact.jpg"} alt="Olena Melentieva" style={styles.image}  className="ContactsImage"/>
  )
}


function Contacts() {
  return (
    <div style={styles.main} className="ContactsMain">
      <div className="d-none d-lg-block">
        <div style={styles.splitPage} className="splitPage">

          <div style={{ ...styles.column, ...styles.left }} className="column left">
            <Info />
          </div>

          <div style={{ ...styles.column, ...styles.right }} className="column right">
            <div style={styles.content}>
              <Img />
            </div>
          </div>

        </div>
      </div>

      <div className="d-lg-none ContactsMainMob" style={styles.mainMob}>
        <div style={styles.mobContainer}>
          <Img />
          <div style={{height: "5vh"}} />
          <Info />
        </div>
      </div>
      
      <NewsLetterSubscription />
    </div>
  );
}


const styles = {
  main: {
    backgroundColor: color.background.main,
  },
  splitPage: {
    height: `calc(90vh - ${headerHeight}px)`,
    display: "flex",
    placeContent: "center",
  },
  column: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    textAlign: "center",
  },
  left: {
    width: "calc(5 / 12 * 100%)",
  },
  h1: {
    fontFamily: font.main,
    fontSize: "2.3rem",
    color: color.font.main,
  },
  header: {
    fontFamily: font.main2,
    fontSize: "2rem",
    fontWeight: 400,
    paddingTop: "4vh",
    color: color.font.main,
  },
  txtContent: {
    fontFamily: font.main2,
    fontSize: "1.5rem",
    fontWeight: 200,
    color: color.font.main,
  },
  contacts: {
    marginBottom: "8vh",
  },
  social: {
    display: "flex",
    marginTop: "8vh",
    justifyContent: "center",
  },
  right: {
    width: "calc(5 / 12 * 100%)",
    fontFamily: font.main,
    fontSize: "max(1.95vw, 22px)",
    fontWeight: 400,
  },
  image: {
    maxHeight: `calc(90vh - ${headerHeight}px - 80px)`,
    maxWidth: "40vw",
    borderRadius: 10,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",    
  },
  mainMob: {
    minHeight: `calc(90vh - ${mobileBrowserHeader}px)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  mobContainer: {
    display: "flex", 
    flexDirection: "column", 
    alignItems: "center",
  },
}

export default Contacts