import { useState } from "react"

import { Nav } from "react-bootstrap"
import { Link } from "react-router-dom"
// constants
import { color, localPaths } from "../../constants"


const LinkMaker = ({eventKey, to, txt}) => {
  const [hovered, setHovered] = useState(false)

  const navStyles = {
    color: hovered ? color.font.homeNavigationHover : color.font.homeNavigation,
    display: "flex",
    justifyContent: "center",
    width: "30vw",
    padding: "1rem",
    margin: "0 auto",
    transition: "background-color 0.3s",
  }
  
  return (
    <Nav.Link 
        as={Link} 
        style={navStyles} 
        to={to} 
        eventKey={eventKey} 
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {txt}
    </Nav.Link>
  )
}


function HomeNavigation() {
  return (
    <div>
      <Nav defaultActiveKey="/home" className="flex-column">
        <LinkMaker
          eventKey="1"
          to={localPaths.about}
          txt="O mnie"
        />
        <span style={styles.line} className="line" />
        <LinkMaker
          eventKey="2"
          to={localPaths.cooperation}
          txt="Usługi"
        />
        <span style={styles.line} className="line" />
        <LinkMaker
          eventKey="3"
          to={localPaths.contacts}
          txt="Kontakt"
        />
      </Nav>
    </div>
  )
}


const styles = {
  navLink: {
    color: color.font.homeNavigation,
    display: "flex",
    justifyContent: "center",
    width: "30vw",
    padding: "1rem",
    margin: "0 auto",
  },
  line: {
    height: 0,
    borderBottom: `1px solid ${color.font.homeNavigation}`,
    opacity: 0.5,
    display: "flex",
    justifyContent: "center",
    width: "30vw",
    margin: "0 auto",
  },

}


export default HomeNavigation
