import { useState } from "react"

import Button from 'react-bootstrap/Button'
import { Link } from "react-router-dom"
// constants
import { color } from "../../constants"


function SubmitBtn() {
  const [hovered, setHovered] = useState(false)

  const buttonStyles = {
    backgroundColor: hovered ? color.button.subscribeHover : color.button.subscribe,
    color: hovered ? color.button.subscribe : color.button.subscribeHover,
    minWidth: 150,
    maxWidth: "25vw",
    margin: 10,
    padding: "15px 25px",
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: color.button.sbuscribeBorder,
    fontWeight: 700,
    transition: "background-color 0.5s, color 0.5s",
  }


  return(
    <Button 
      as={Link}
      type="submit"
      style={buttonStyles} 
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      ZAPISZ SIĘ
    </Button>
  )
}


export default SubmitBtn
