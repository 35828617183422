import React from "react"
// import ReactDOM from "react-dom"  // old implimentation
import { createRoot } from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import ReactGA from "react-ga4"

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css"

// Initialize Google Analytics
if (process.env.NODE_ENV === 'development') {
  ReactGA.initialize("G-4Q4L8B2ZCE!")
} else {
  ReactGA.initialize("G-4Q4L8B2ZCE")
}


// old implimentation
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// )
createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
