import { Link } from "react-router-dom"
// constants
import { color, footerHeight, font, localPaths } from "../constants"


const Footer = () => {

  return (
    <footer id={"footer"} style={styles.footer}>
      <Link 
        to={localPaths.policies}
        style={styles.link}
      >
        Policies
      </Link>
    </footer>
  )
}


const styles = {
  footer: {
    width: "100%",
    height: footerHeight,
    padding: "10px 0",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color.background.footer,
    fontSize: font.size.small,
  },
  link: {
    color: color.font.footer,
  },
}


export default Footer
