
// constants
import { font, color } from "../../constants"


function Headers() {
  return (
    <div>
      <h1 style={styles.h1} className="h1">O MNIE</h1>
      <hr style={{ margin: "0 5vw" }}/>
      <h3 style={styles.h3}>Nazywam się Olena Melentieva. <br/><br/>Jestem dyplomowanym coachem po szkole akredytowanej przez ICF.</h3>
      <p style={styles.pHead}>Mam bogate doświadczenie. Moje życie zawodowe od początków jest bardzo intensywne, pełne sukcesów, dużej ilości zdobywanej wiedzy i doświadczenia.</p>
    </div>
  )
}


function AboutTxt() {
  return (
    <div style={styles.descr}>
      <p style={styles.pDescr}>
        Przez kilka lat pracowałam na kierowniczym stanowisku w sektorze finansowym i IT. Praca z ludźmi zawsze była dla mnie ekscytująca i interesująca. Mentoring stanowił podstawę mojego działania wewnątrz organizacji, w których pracowałam, dlatego po 15 latach pracy w dużych firmach postanowiłam pójść za głosem serca i skupić się na tym, co kocham najbardziej, pracy z ludźmi.
      </p>
      <p style={styles.pDescr}>
        Uważam, że w życiu jak i w pracy bardzo ważne jest ciągłe pogłębianie i aktualizowanie własnej wiedzy. Dlatego nieustannie pracuję nad rozwojem osobistym, ciągle uczę się, szukam i zdobywam doświadczenie przez praktykowanie nowych metod i technik, które poznaję.
      </p>
      <p style={styles.pDescr}>
        Studiuję psychologię i psychoterapię ACT. Mam różne zainteresowania, w tym praca z ciałem i psychosomatyka, neuronauka, biologia totalna, rytmy okołodobowe, zdrowy obraz życia, mindfullness, ajurweda, aromaterapia, yoga. Moje pasje z innych dziedzin to sztuka i balet. Ubóstwiam naturę, dlatego dużo czasu spędzam na świeżym powietrzu, dużo czytam, gram na pianino, ineresuję się językami obcymi, kulturą. Nacodzień dbam o równowagę pomiędzy pracą, życiem rodzinnym, realizacją pasji i odpoczynkiem.
      </p>
      <p style={styles.pDescr}>
        Jestem gotowa pomóc i dzielić się swoimi doświadczeniami i narzędziami, aby każdy mógł osiągnąć swoje cele i cieszyć się pełnym potencjałem.
      </p>
    </div>
  )
}


function Img({mob=false}) {
  return(
    <img src={"/photos/main.jpg"} alt="Olena Melentieva" style={mob ? styles.imgMob : styles.img} className="img" />
  )
}


const ImgMob = () => <Img mob={true} />


function AboutDescr() {
  return(
    <div>

      <div className="d-none d-lg-block">
        <div style={styles.splitPage} className="splitPage">
          <div style={{ ...styles.column, ...styles.left }} className="column left">
            <div style={styles.content} className="content">
              <Img />
            </div>
          </div>
          <div style={{ ...styles.column, ...styles.right }} className="column right">
            <Headers />
          </div>
        </div>
        <div style={{paddingLeft: "15vw"}}>
          <AboutTxt />
        </div>
      </div>

      <div className="d-lg-none">
        <div style={styles.contentMob} className="contentMob">
          <Headers />
          <div style={{marginTop: "2rem", textAlign: "center"}}>
            <ImgMob />
          </div>
          <AboutTxt />
        </div>
      </div>

    </div>
  )
}


const styles = {
  splitPage: {
    display: "flex",
    padding: "10vh 5vw 0",
  },
  column: {
    height: "100%",
    display: "flex",
  },
  content: {
    maxWidth: "90%",
    textAlign: "justify"
  },
  contentMob: {
    textAlign: "center",
    margin: "5vw 5vw",
  },
  // LEFT
  left: {
    width: "calc(5 / 12 * 100%)",
  },
  h1: {
    color: color.font.main,
    paddingLeft: "5vw",
    paddingBottom: "1rem",
    font: font.main,
    fontSize: "4rem",
    fontWeight: 300,
    lineHeight: "3rem",
  },
  h3: {
    color: color.font.main,
    marginTop: "4rem", 
    paddingLeft: "5vw", 
    paddingRight: "10vw", 
    fontFamily: font.main, 
    fontSize: "1.5rem", 
    fontWeight: 400,
  },
  pHead: {
    color: color.font.main,
    marginTop: "4rem", 
    paddingLeft: "5vw", 
    paddingRight: "10vw", 
    fontFamily: font.main, 
    fontSize: "1.3rem", 
    fontWeight: 300,
  },
  descr: {
    padding: "4rem 10vw 0 5vw",
    color: color.font.main,
    fontFamily: font.main, 
    fontSize: "1.3rem", 
    fontWeight: 300, 
  },
  pDescr: {
    paddingBottom: 20,
  },
  // RIGHT
  right: {
    width: "calc(7 / 12 * 100%)",
  },
  img: {
    width: "100%",
    borderRadius: 20,
    marginLeft: "5vw",
  },
  imgMob: {
    maxHeight: "20vh",
    borderRadius: "1vh",
    marginBottom: "3vh",
  },
}

  export default AboutDescr
