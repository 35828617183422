import { useEffect } from "react"

import AboutDescr from "./AboutDescr"
import AboutVision from "./AboutVision"
import AboutCertificates from "./AboutCertificates"

import NewsLetterSubscription from "../Form/NewsLetterSubsciption/NewsLetterSubscription"

// constants
import { color } from "../../constants"


function About() {
  useEffect(() => {
    document.title = "Olena Melentieva - O mnie"
  }, [])

  return (
    <div style={styles.containerCustom} className="containerCustom">
      <AboutDescr />
      <AboutCertificates />
      <AboutVision />
      <NewsLetterSubscription />
    </div>
  )
}


const styles = {
  containerCustom: {
    display: "block", 
    backgroundColor: color.background.main,
    padding: "40 0",
  },
  btn: {
    padding: "60px 0",
    textAlign: "center"
  },
}


export default About
