import { useEffect } from "react"
import { Routes, Route, useLocation } from "react-router-dom"
import ReactGA from "react-ga4"
// components
import Home from "./Home/Home"
import About from "./About/About"
import Contacts from "./Contacts/Contacts"
import Cooperation from "./Cooperation/Cooperation"
import Policies from "./Policies"
//import Blog from "./blog/Blog"
//import Blog1 from "./blog/Blog1"
import NotFound from "./NotFound"
// constants
import { color, localPaths } from "../constants"


function Main() {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search })
  }, [location])

  useEffect(() => {
    window.scrollTo(0, 0) // Scroll to top on page change
  }, [location]);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual" // Disable automatic scroll restoration
    }
  }, [])

  useEffect(() => {
    const header = document.getElementById("header")
    const footer = document.getElementById("footer")
    if (location.pathname === localPaths.home) {
      document.getElementById("main").style.minHeight = "100vh"
      header.style.display = "none"
      footer.style.display = "none"
    }
    else {
      header.style.display = "block"
      footer.style.display = "flex"
    }
  }, [location])


  return (
    <main id={"main"} style={ styles.main }>
      <Routes>
        <Route path={localPaths.home} element={ <Home/> } />
        <Route path={localPaths.about} element={ <About/> } />
        <Route path={localPaths.contacts} element={ <Contacts/> } />
        <Route path={localPaths.cooperation} element={ <Cooperation/> } />
        <Route path={localPaths.policies} element={ <Policies/> } />
        <Route path="*" element={ <NotFound/> } />
      </Routes>
    </main>
  )
}


const styles = {
  main: {
    width: "100%",
    margin: "auto",
    backgroundColor: color.background.main,
  }
}


export default Main
