import { Col, Row } from "react-bootstrap"
// constants
import { color, font } from "../../constants"


const certificates = [
  "School for Coaches accredited by the International Coaching Federation with the recognition of the International Education Society.",
  "Institute of Psychotherapy and Psychosomatics. Mastering and practical training of consultant skills.",
  "Reality slap or how to deal with a crisis, loss or trauma. Prowadzone przez Dr. Russa Harrisa. Organizowane przez Uczę się ACT.",
  "Emocje, myśli i ciało w zmianie. Coaching z wykorzystaniem ucieleśnionych obrazów. Prowadząca Dorota Raniszewska. Organizowane przez Stowarzyszenie European Mentoring & Coaching Council Poland.",
  "Jestem członkiem Association for Contextual Behavioral Science (ACBS) ta ACBS Polska",
]


function AboutCertificates() {
  return(
    <div className="d-block main" style={styles.main}>
      <h2 style={styles.h2} className="h2">MOJE CERTYFIKATY</h2>
      <Row>
        <div className="col-12 col-lg-6">
          <Col>
            <ul style={styles.ul} className="ul">
              <hr style={styles.hr}/>
              {certificates.map(certificate => (
                <li key={certificate} style={styles.li} className="li">
                  <div style={styles.certTxt} className="certTxt">
                    {certificate}
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        </div>

        <div className="col-12 col-lg-3">
          <Col>
            <img src={"/images/cert/cert1.jpg"} alt="Sertificate from International education society in London" style={styles.certImg} className="certImg" />
          </Col>
        </div>
        <div className="col-12 col-lg-3">
          <Col>
            <img src={"/images/cert/cert2.jpg"} alt="Sertificate from Institute of Psychoterapy and Psychosomatics" style={styles.certImg} className="certImg" />
            <img src={"/images/cert/cert3.jpg"} alt="Sertificate of completion the education programme of Coaching" style={styles.certImg} className="certImg" />
            <img src={"/images/cert/cert4.jpg"} alt="Sertificate of completion online education school of Coaching" style={styles.certImg} className="certImg" />
          </Col>
        </div>

      </Row>
    </div>
  )
}


const styles = {
  main: {
    padding: "0 1rem",
    margin: "0 5vw",
    textAlign: "center",
  },
  // LEFT
  h2: {
    marginTop: 200,
    paddingLeft: "7vw",
    fontFamily: font.main,
    fontSize: "2rem",
    fontWeight: 500,
    color: color.font.main,
  },
  hr: {
    paddingBottom: 20,
  },
  ul: {
    listStyle: "none",
    paddingLeft: "5vw",
    paddingRight: "4vw",
  },
  li: {
    position: "relative",
    marginBottom: "2rem",
    paddingLeft: "2vw",
    fontFamily: font.main2,
    fontSize: font.size.main,
    fontWeight: 320,
    color: color.font.main,
  },
  certTxt: {
    zIndex: 1,
    position: "inherit",
  },
  // RIGHT
  certImg: {
    width: "100%",
    padding: 10,
  },
  "@media (max-width: 768px)": {
    li: {
      textAlign: "center",
    },
  },
}


export default AboutCertificates
