import { useState } from "react"

import SubmitBtn from "../../Buttons/Submit"
// constants
import { color, font } from "../../../constants"


function FormComponent({ mob=false }) {
  const [subscribed, setSubscribed] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault()

    const formData = {}
    for (let i = 0; i < e.target.elements.length; i++) {
      const input = e.target.elements[i];
      if (input.type !== "submit") {
        formData[input.name] = input.value;
      }
    }


    const form = document.createElement("form")
    form.style.display = "none"
    form.method = "post"
    form.target = "_blank"
    form.action = "https://form.flodesk.com/forms/64678813bdfaea673c776ffb/submit"
  
    const firstNameInput = document.createElement("input")
    firstNameInput.type = "hidden"
    firstNameInput.name = "firstName"
    firstNameInput.value = formData.firstName
    form.appendChild(firstNameInput)

    const emailInput = document.createElement("input")
    emailInput.type = "hidden"
    emailInput.name = "email"
    emailInput.value = formData.email
    form.appendChild(emailInput)
  
    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
  
    setSubscribed(true)
  }

  return(
    <div>
      <style>
        {
          `
            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: ${color.font.placeholder};
              opacity: .5; /* Firefox */
            }
            
            :-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: ${color.font.placeholder};
            }
            
            ::-ms-input-placeholder { /* Microsoft Edge */
              color: ${color.font.placeholder};
            }
          `
        }
      </style>

      {subscribed ? (
        <p style={{...styles.descr, fontSize: "2.2rem"}}>
          Dziękuję za dołączenie do newslettera!
        </p>
      ) : (
        <form 
          style={mob ? null : styles.form}
          onSubmit={handleSubmit}
        >
          <div>
            <input 
              type="text" 
              maxLength="255"
              name="firstName" 
              placeholder="Imię" 
              style={styles.input}
              className="placeholder-color"
              required 
            />
          </div>

          <div>
            <input 
              type="email" 
              maxLength="255" 
              name="email" 
              placeholder="Email" 
              style={styles.input}
              required 
            />
          </div>

          <div>
            <input type="text" maxLength="255" name="confirm_email_address" style={{display: "none"}} />
          </div>

          <div>
            <SubmitBtn />
          </div>
        </form>
      )}
    </div>
  )
}


const styles = {
  descr: {
    padding: 0,
    margin: 0,
    font: font.main,
    color: color.font.newsletterSubscription,
    fontSize: "1rem",
    fontWeight: 200,
  },
  form: {
    display: "flex",
    marginTop: 10,
    justifyContent: "center",
  },
  input: {
    backgroundColor: color.background.newsletterSubscription,
    margin: 10,
    padding: "15px 25px",
    borderRadius: 10,
    border: "solid white 1px",
    color: color.font.newsletterSubscription,
  },
}


export const Form = () => <FormComponent mob={false} />
export const FormMob = () => <FormComponent mob={true} />
