// constants
import { color, font } from "../../constants"


function CooperationQuote() {
  return(
    <div style={styles.main} className="main">
      <p style={styles.p} className="p">"W centrum twojej istoty masz odpowiedź.</p>
      <p style={styles.p} className="p">Ty wiesz, kim jesteś i wiesz, co chcesz."</p>
      <br/>
      <p style={styles.p} className="p">Laozi</p>
    </div>
  )
}


const styles = {
  main: {
    padding: "2rem",
    backgroundColor: color.background.bright2,
    // borderRadius: 40,
    textAlign: "center",
    fontSize: "1.4rem",
    fontFamily: font.main,
    fontWeight: 700,
  },
  p: {
    margin: 0,
    color: color.font.secondary,
  },
  // img: {
  //   width: 64,
  //   marginTop: "1rem",
  //   marginBottom: "1rem",
  // },
}


export default CooperationQuote
