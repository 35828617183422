import { useState } from "react"


const Social = ({ img, alt, link }) => {
  const [hovered, setHovered] = useState(false)

  const styles = {
    opacity: hovered ? 0.5 : 1,
    transition: "opacity 0.7s",
  }

  return (
    <span>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img 
          src={img} 
          alt={alt}
          style={styles}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        />
      </a>
    </span>
  )
}


export const Facebook = () => {
  return (
    <Social img="/icons/facebook.png" alt="Linkt to Facebook profile" link="https://www.facebook.com/profile.php?id=100086905801367"/>
  )
}


export const Instagram = () => {
  return (
    <Social img="/icons/instagram.png" alt="Linkt to Instagram profile" link="https://instagram.com/olena.melentieva"/>
  )
}
