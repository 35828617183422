import { useEffect } from "react"
import { Col, Row } from "react-bootstrap"

import CooperationInfo from "./CooperationInfo"
import CooperationQuote from "./CooperationQuote"
import { CooperationService, services } from "./CooperationService"

import NewsLetterSubscription from "../Form/NewsLetterSubsciption/NewsLetterSubscription"
// constants
import { color } from "../../constants"



function Cooperation() {
  useEffect(() => {
    document.title = "Olena Melentieva - Współpraca. Jak pracuję."
  }, [])

  return (
    <div>

      <div className="d-none d-lg-block">
        <Row style={styles.row}>
          <div className="col-lg-8">
            <Col>
              <CooperationInfo />
            </Col>
          </div>

          <div className="col-lg-4">
            <Col>
            <div style={styles.services}>
              {services.map(service => (
                <CooperationService key={service.title} title={service.title} linkTo={service.linkTo} bgColor={service.bgColor}/>
              ))}
            </div>
            </Col>
          </div>
        </Row>

        <CooperationQuote />
      </div>

      <div className="d-lg-none mob" style={styles.mob}>
        <div style={styles.content} className="content">
          <div style={styles.servicesMob}>
            {services.map(service => (
              <CooperationService key={service.title} title={service.title} linkTo={service.linkTo} bgColor={service.bgColor}/>
            ))}
          </div>
          <CooperationInfo />
          <CooperationQuote />
        </div>
      </div>
      
      <NewsLetterSubscription />

    </div>
  )
}


const styles = {
  content: {
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    paddingTop: 40,
  },
  services: {
    display: "flex", 
    flexDirection: "column",
    placeContent: "center", 
    marginTop: 120, 
    marginBottom: 60,
  },
  btn: {
    paddingBottom: 60,
    textAlign: "center"
  },
  mob: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: color.background.main,
  },
  servicesMob: {
    display: "block",
    marginBottom: 60,
    textAlign: "-webkit-center",
  },
}

export default Cooperation