// constants
import { color, font } from "../constants"


const Policies = () => {
  return (
    <div style={styles.main}>
      <h2>POLITYKA PRYWATNOŚCI</h2>
 
      <p>
        Niniejsza Polityka stanowi podstawową informację o celach, sposobach przetwarzania oraz bezpieczeństwie Twoich danych osobowych, jako użytkownika strony: olenamelentieva.com (zwanej dalej Stroną). Zapoznając się z Polityką prywatności dowiesz się kto jest administratorem Twoich danych osobowych, jakie dane osobowe są przez Stronę zbierane, w jakich celach są wykorzystywane oraz jak są chronione. 
      </p>
      
      <p>
        §1. Informacje podstawowe. 
      </p>
      
      <p>
        Administratorem Twoich danych osobowych jest: Olena Melentieva 
        Kontakt z Administratorem jest możliwy za pośrednictwem:  
        poczty e-mail: olenamelentieva@gmail.com
      </p>
        
      <p>
        §2. Zasady przetwarzania danych. 
      </p>
        
      <p>
        Administrator przetwarza dane osobowe z poszanowaniem następujących zasad: 
        w oparciu o podstawę prawną i zgodnie z prawem (legalizm); 
        rzetelnie i uczciwie (rzetelność);  
        w sposób przejrzysty dla osoby, której dane dotyczą (transparentność); 
        w konkretnych celach i nie „na zapas” (minimalizacja); 
        nie więcej niż potrzeba (adekwatność); 
        z dbałością o prawidłowość danych (prawidłowość); 
        nie dłużej niż potrzeba (czasowość); 
        zapewniając odpowiednie bezpieczeństwo danych (bezpieczeństwo).
      </p> 
        
      <p>
        §3. Cele, podstawy prawne i zakres przetwarzania danych. 
      </p>
        
      <p>
        Twoje dane będziemy przetwarzać: 
        w celach kontaktowych, aby udzielić ci odpowiedzi na Twoją wiadomość przesłaną nam bezpośrednio drogą e-mailową lub za pośrednictwem formularza na podstawie realizacji prawnie uzasadnionego interesu Administratora związanego z koniecznością udzielenia Ci odpowiedzi. 
        w celach technicznych przy użyciu plików cookies (ciasteczka techniczne), na podstawie realizacji prawnie uzasadnionego interesu Administratora związanego z prawidłowym działaniem i funkcjonowaniem Strony. 
        w celach statystycznych i analitycznych, jeśli wyraziłeś zgodę na wykorzystanie przez nas plików cookies do celów analitycznych (ciasteczka analityczne). 
        w celach marketingowych, jeśli wyraziłeś zgodę na wykorzystanie przez nas plików cookies do celów marketingowych (ciasteczka marketingowe) 
        w celach związanych z prowadzeniem przez nas profilu firmowego w mediach społecznościowych (takich jak Facebook / Instagram) na zasadach określonych przez właścicieli danego medium (portalu) i informowania w nim o naszych produktach, promocjach i innych wiadomościach dotyczących naszej działalności, co uważamy za nasz prawnie uzasadniony interes, 
      </p>
      <p>
        Przetwarzamy Twoje dane w zakresie: 
        jeśli prześlesz nam wiadomość e-mail to będziemy przetwarzać dane, które będą w niej zawarte. Przekazanie nam danych w e-mailu następuje dobrowolnie. W każdym czasie możesz zwrócić się do nas z prośbą o usunięcie tych danych. 
        jeśli skorzystać z formularza kontaktowego na naszej stronie to będziemy przetwarzać dane w postaci twojego adresu e-mail oraz imienia. Podanie tych danych stanowi warunek udzielenia przez nas odpowiedzi na Twoje zapytanie.  
        pozostałe dane, które Administrator przetwarza to adres IP i inne dane zapisywane w plikach cookies. To Ty decydujesz w jakim zakresie będziemy mogli wykorzystać pliki cookies z Twoimi danymi. Przekazanie nam danych w ten sposób następuje dobrowolnie. W każdym czasie możesz zmienić swoje preferencje. Możesz to zrobić w swojej przeglądarce internetowej lub też po usunięciu zapisanych plików cookies pochodzących z naszej Strony. 
        jeśli polubisz lub w inny sposób dołączysz do naszego profilu w mediach społecznościowych (np. Facebook) będziemy przetwarzać Twoje dane, które podałeś w danym medium i do których będziemy mieli dostęp. W celu zakończenia przetwarzania możesz zakończyć subskrypcję naszego profilu.  
        API zewnętrznych partnerów: Nasza strona może korzystać z API (interfejsów programistycznych aplikacji) dostarczanych przez zewnętrznych partnerów w celu zintegrowania różnych funkcjonalności, takich jak mapy, usługi analityczne, reklamowe czy media społecznościowe. Korzystanie z tych API może prowadzić do tworzenia dodatkowych ciasteczek lub korzystania z istniejących ciasteczek w celu przetwarzania danych użytkowników. Zewnętrzni partnerzy mogą przechowywać i przetwarzać te dane zgodnie z własnymi politykami prywatności. 
      </p>  
        
      <p>
        §4. Odbiorcy danych oraz zamiar przekazywania danych do Państwa spoza EOG lub organizacji międzynarodowej. 
      </p>
        
      <p>
        Odbiorcą Twoich danych osobowych są:  
        jeśli wyraziłeś zgodę na ciasteczka marketingowe, Twoje dane zapisane w plikach cookies, będą przekazane do naszych partnerów reklamowych. 
        jeśli wyraziłeś zgodę na ciasteczka analityczne, Twoje dane zapisane w plikach cookies, będą przetwarzane przez podmiot dostarczający oprogramowanie do analizy ruchu naszej stronie (np. Google Analytics) 
        Twoje dane nie są przekazywane do Państw spoza EOG lub organizacji międzynarodowych. 
      </p> 
        
      <p>
        §5. Termin przechowywania danych. 
      </p>
        
      <p>
        Nie jesteśmy w stanie ustalić przez jaki okres przetwarzać będziemy Twoje dane osobowe. Jeśli przekazałeś nam swoje dane w formularzu lub wiadomości e-mail w celach kontaktowych to Twoje dane będziemy przetwarzać do momentu złożenia przez Ciebie żądania usunięcia tych danych lub sprzeciwu wobec przetwarzania, ale również w przypadku, w którym uznamy, że zrealizowaliśmy prawnie uzasadniony interes Administratora.  
      </p>
        
      <p>
        §6. Prawa użytkowników. 
      </p>
        
      <p>
        W związku z przetwarzaniem Twoich danych przysługują Ci następujące prawa:  
        dostępu do treści swoich danych oraz  
        prawo ich sprostowania, usunięcia, ograniczenia przetwarzania,  
        prawo do przenoszenia danych,  
        prawo wniesienia sprzeciwu,  
        prawo do cofnięcia zgody na ich przetwarzanie w dowolnym momencie i w dowolnej formie, chyba że przetwarzanie Państwa danych odbywa się w celu wykonywania umowy przez Administratora, w celu wywiązania się przez Administratora z obowiązków prawnych względem instytucji państwowych lub w celu realizacji prawnie uzasadnionych interesów Administratora.  
        Masz także prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych (na adres Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa).  
        Więcej informacji w przedmiocie ochrony danych osobowych mogą Państwo otrzymać na stronie internetowej Urzędu Ochrony Danych Osobowych: www.uodo.gov.pl 
      </p>
        
      <p>
        §7 Informacja o ciasteczkach 
      </p>
        
      <p>
        Na naszej stronie wykorzystujemy ciasteczka (pliki cookies).  
        Ciasteczka (pliki cookies) są niewielkimi plikami tekstowymi przechowywanymi na urządzeniach użytkowników podczas przeglądania strony internetowej. Są one wykorzystywane w celu ułatwienia korzystania z naszej strony, poprawienia jakości usług oraz umożliwienia analizy ruchu i zachowań użytkowników. 
        Ciasteczka mogą być stałe (trwałe) lub tymczasowe (sesyjne). Stałe ciasteczka są przechowywane na urządzeniu użytkownika przez określony czas lub do momentu ich usunięcia przez użytkownika. Tymczasowe ciasteczka są usuwane po zamknięciu przeglądarki. 
        Wykorzystujemy ciasteczka własne oraz ciasteczka stron trzecich. Ciasteczka własne są wykorzystywane w celu zapewnienia prawidłowego działania strony, personalizacji treści, zapisywania preferencji użytkowników oraz analizy statystyk. Ciasteczka stron trzecich są wykorzystywane przez zewnętrzne narzędzia analityczne, reklamowe oraz do integracji z mediami społecznościowymi. 
        Użytkownicy mają prawo kontrolować i ograniczać sposób, w jaki ciasteczka są wykorzystywane na naszej stronie. Większość przeglądarek internetowych umożliwia zarządzanie plikami cookies, w tym blokowanie, ograniczanie lub usuwanie ciasteczek. Szczegółowe informacje na temat zarządzania ciasteczkami można znaleźć w ustawieniach przeglądarki użytkownika. 
        Należy pamiętać, że wyłączenie lub ograniczenie ciasteczek może wpłynąć na funkcjonalność strony oraz jakość świadczonych usług. 
        Na naszej stronie wykorzystujemy różne rodzaje ciasteczek, aby lepiej dostosować się do potrzeb użytkowników i zapewnić optymalne korzystanie ze strony.   
        Ciasteczka techniczne: Są niezbędne do prawidłowego funkcjonowania witryny. Umożliwiają utrzymanie sesji użytkownika, obsługę formularzy, zapamiętywanie preferencji oraz zapewnienie bezpieczeństwa strony. 
        Ciasteczka marketingowe: Pozwalają na personalizację reklam oraz prowadzenie działań remarketingowych, które są dostosowane do zainteresowań i preferencji użytkowników. Służą również do mierzenia skuteczności kampanii reklamowych oraz optymalizacji treści reklamowych. 
        Ciasteczka analityczne: Są wykorzystywane do zbierania informacji o sposobie korzystania z witryny przez użytkowników, takich jak liczba odwiedzin, strony odwiedzane, czas spędzony na stronie czy źródło ruchu. Uzyskane dane pomagają w analizie i optymalizacji strony, poprawiając jej funkcjonowanie i dostosowując treści do potrzeb użytkowników. 
      </p>

      <p>
        §8 Dlaczego korzystamy z ciasteczek? 
      </p>
        
      <p>
        Komfort korzystania ze strony: Ciasteczka (pliki cookies) mogą znacząco wpłynąć na komfort korzystania ze strony internetowej.  
        Personalizacja treści: Ciasteczka pozwalają na dostosowanie wyświetlanych treści do preferencji i zainteresowań użytkownika, co sprawia, że strona staje się bardziej atrakcyjna i użyteczna. 
        Zapamiętywanie ustawień: Dzięki ciasteczkom strona może zapamiętać wybrane przez użytkownika ustawienia, takie jak język, układ czy kolorystyka, co pozwala na szybsze i wygodniejsze korzystanie z serwisu. 
        Logowanie: Ciasteczka umożliwiają przechowywanie informacji o zalogowanym użytkowniku, co pozwala na łatwe przełączanie się między różnymi sekcjami strony bez konieczności ponownego logowania. 
        Utrzymanie sesji: Dzięki ciasteczkom strona może śledzić aktywność użytkownika na różnych podstronach, co pozwala na łatwe wracanie do ostatnio odwiedzanych sekcji lub kontynuowanie niedokończonych działań, takich jak wypełnianie formularzy. 
        Optymalizacja wydajności: Ciasteczka mogą być wykorzystane do analizy ruchu i zachowań użytkowników na stronie, co pomaga w optymalizacji jej wydajności oraz dostarczaniu treści o wysokiej jakości. 
        Reklamy: Ciasteczka umożliwiają wyświetlanie spersonalizowanych reklam, które są dopasowane do zainteresowań i potrzeb użytkownika. Dzięki temu, reklamy są mniej inwazyjne i bardziej adekwatne. 
        Integracja z mediami społecznościowymi: Ciasteczka pozwalają na integrację z serwisami społecznościowymi, takimi jak Facebook, Twitter czy Instagram, co umożliwia łatwe udostępnianie treści oraz korzystanie z dodatkowych funkcji, takich jak komentowanie czy polubienia. 
      </p>

      <p>
        §9 Funkcje ciasteczek technicznych 
      </p>
        
      <p>
        Utrzymanie sesji użytkownika: Ciasteczka techniczne umożliwiają utrzymanie sesji użytkownika podczas poruszania się po stronie, co pozwala na przechodzenie między różnymi sekcjami witryny bez konieczności ponownego logowania czy wprowadzania tych samych informacji. 
        Zapamiętywanie preferencji użytkownika: Dzięki ciasteczkom technicznym strona może przechowywać informacje na temat preferencji użytkowników, takie jak wybrany język, wielkość czcionki, ustawienia kolorystyczne czy inne elementy personalizacji. 
        Obsługa formularzy: Ciasteczka techniczne wspomagają funkcjonowanie formularzy na stronie, takich jak formularze logowania, rejestracji czy kontaktowe, zapewniając prawidłowe przetwarzanie danych wprowadzanych przez użytkowników. 
        Optymalizacja wydajności strony: Użycie ciasteczek technicznych umożliwia monitorowanie wydajności strony oraz szybkość ładowania poszczególnych elementów, co pozwala na optymalizację i poprawę jakości świadczonych usług. 
        Bezpieczeństwo: Ciasteczka techniczne pomagają w zapewnieniu bezpieczeństwa witryny i jej użytkowników, na przykład poprzez weryfikację tożsamości użytkowników, wykrywanie prób włamań czy ochronę przed atakami typu CSRF (Cross-Site Request Forgery). 
      </p>

      <p>
        §10 Funkcje ciasteczek analitycznych 
      </p>

      <p> 
        Pomiar ruchu na stronie: Ciasteczka analityczne pozwalają na monitorowanie liczby odwiedzin, unikalnych użytkowników i wyświetleń stron, co daje ogólny obraz popularności i wydajności witryny. 
        Analiza zachowań użytkowników: Dzięki ciasteczkom analitycznym można zbierać informacje o sposobie, w jaki użytkownicy poruszają się po stronie, na przykład czas spędzony na poszczególnych podstronach, ścieżki nawigacji oraz miejsca, w których opuszczają witrynę. 
        Optymalizacja konwersji: Ciasteczka analityczne pomagają w identyfikowaniu elementów strony, które wpływają na skuteczność osiągania celów biznesowych, takich jak rejestracja, złożenie zamówienia czy wypełnienie formularza kontaktowego. 
        Segmentacja użytkowników: Użycie ciasteczek analitycznych umożliwia grupowanie użytkowników według różnych kryteriów co pozwala na lepsze dostosowanie treści i oferty do potrzeb poszczególnych segmentów. 
        Testowanie A/B: Ciasteczka analityczne są wykorzystywane do przeprowadzania testów A/B, które polegają na porównaniu dwóch wersji strony internetowej, aby sprawdzić, która z nich lepiej spełnia założone cele. Testy te pomagają w optymalizacji wyglądu, funkcjonalności i treści witryny. 
      </p>
      
      <p>
        §11 Funkcje ciasteczek reklamowych 
      </p>

      <p>
        Spersonalizowane reklamy: Ciasteczka marketingowe umożliwiają wyświetlanie reklam dopasowanych do zainteresowań, preferencji i historii przeglądania użytkownika, co sprawia, że są one bardziej atrakcyjne i efektywne. 
        Remarketing: Dzięki ciasteczkom marketingowym można śledzić użytkowników, którzy odwiedzili witrynę, ale nie dokonali zakupu lub innej akcji, i ponownie kierować do nich reklamy, aby zachęcić do powrotu na stronę i finalizacji transakcji. 
        Pomiar skuteczności reklam: Ciasteczka marketingowe pozwalają na monitorowanie wyników kampanii reklamowych, takich jak liczba kliknięć, konwersji czy wyświetleń, co umożliwia optymalizację strategii marketingowej. 
        Zarządzanie reklamami w wielu kanałach: Użycie ciasteczek marketingowych ułatwia koordynację i integrację działań reklamowych prowadzonych w różnych kanałach, takich jak wyszukiwarki, media społecznościowe czy strony partnerskie. 
        Testowanie i optymalizacja treści reklamowych: Ciasteczka marketingowe są wykorzystywane do przeprowadzania testów A/B oraz innych metod analizy, które pozwalają na ocenę, jakie przekazy, formaty czy kreatywności reklamowe są najbardziej skuteczne i angażujące dla użytkowników.  
      </p>

    </div>
  )
}


const styles = {
  main: {
    padding: "2vh 2vw",
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: color.background.main,
    fontSize: font.size.small,
  },
}


export default Policies
