import {Form, FormMob} from "./NewsLettersSubscriptionForm"
// import "./NewsLetterSubscription.css"
// constants
import { color, font } from "../../../constants"


function NewsLetterSubscription() {
  return(
    <div style={styles.main}>
      <div>
        <p style={styles.header}>
          Dołącz do mojego newslettera
        </p>

        <p style={styles.descr}>
          Dołączając do newslettera, będziesz otrzymywać wiadomości o nowościach, produktach i usługach od Olena Melentieva (https://olenamelentieva.com)
        </p>
        <p style={styles.descr}>
          W każdej chwili możesz zrezygnować z otrzymywania newslettera. Więcej informacji o przetwarzaniu danych osobowych znajdziesz w Polityce Prywatności.
        </p>

        <div className="d-none d-lg-flex" style={styles.form}>
          <Form />
        </div>
        <div className="d-lg-none">
          <FormMob />
        </div>
        
      </div>
    </div>
  )
}


const styles = {
  main: {
    width: "100%",
    padding: 20,
    textAlign: "center",
    backgroundColor: color.background.newsletterSubscription
  },
  header: {
    font: font.main,
    fontSize: "2.5rem",
    fontWeight: 600,
    color: color.font.newsletterSubscription,
  },
  descr: {
    padding: 0,
    margin: 0,
    font: font.main,
    color: color.font.newsletterSubscription,
    fontSize: "1rem",
    fontWeight: 200,
  },
  form: {
    display: "flex",
    marginTop: 10,
    justifyContent: "center",
  },
}


export default NewsLetterSubscription
