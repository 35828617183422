// constants
import { color, font } from "../../constants"


const points = [
  "Pracuję holistycznie: korzystam z różnych metod, łączę ich.",
  "Korzystam z technik i metod terapii ACT.",
  "Zwracam uwagę na pracę z ciałem, aby nauczyć się słyszeć jego sygnały, co pomaga w zrozumieniu nas samych i kierunków, jakie będą nam służyć.",
  "Pracuję z wartościami i mocnymi stronami osobowości. To ukieronkowuje, daje siły do działania, pomaga w trudnych i przejsciowych okresach naszego życia.",
  "Przygotowuję zadania i materiały dla klientów, bo praca własna pomiędzy sesjami jest również ważna.",
  "Partnerstwo, zaufanie, klarowność, akceptacja, działania, zaangazowanie - moje kluczowe słowa w pracy z klientem.",
  "Pracuję z klientami na wielu obszarach życia, w tym w rozwoju kariery, zwiększaniu pewności siebie, radzeniu sobie ze stresem, zarządzaniu czasem i równowagą między życiem zawodowym a prywatnym. Cieszę się, że mogę być częścią tego pięknego procesu transformacji i wspierać innych w ich drodze do sukcesu.",
]


function Points({mob=false}) {
  return(
    <div style={ mob ? {padding: "0 5vw"} : null }>
      { points.map(point => (
        <li key={point} style={styles.li} className="li">
          {point}
        </li>
      ))}
    </div>
  )
}


const PointsMob = () => <Points mob={true} />


function Items() {
  return(
    <div>
      <h1 style={styles.h1} className="h1">JAK PRACUJĘ</h1>
      <hr style={styles.hr} />
      <ul style={styles.ul} className="ul">

        <div className="d-none d-lg-block">
          <Points />
        </div>

        <div className="d-lg-none">
          <PointsMob />
        </div>

      </ul>
    </div>
  )
}


function CooperationInfo() {
  return(
    <div>

      <div className="d-none d-lg-block" style={styles.main}>
        <Items />
      </div>

      <div className="d-lg-none" style={styles.mainMob}>
        <Items />
      </div>

    </div>
  )
}


const styles = {
  main: {
    padding: "4rem 10vw"
  },
  mainMob: {
    padding: "4rem 0",
    textAlign: "center",
  },
  h1: {
    paddingLeft: "4vw",
    marginBottom: "2rem",
    fontFamily: font.main,
    fontSize: "2.3rem",
    color: color.font.main,
  },
  hr: {
    color: color.font.main,
    // margin: "0 5vw",
  },
  ul: {
    listStyle: "none",
    paddingLeft: "4vw",
    paddingRight: "4vw",
  },
  li: {
    position: "relative",
    display: "flex",
    marginBottom: "2rem",
    color: color.font.main,
    fontFamily: font.main2,
    fontSize: font.size.main,
    fontWeight: 320,
  },
  dash: {
    height: "1rem",
    marginTop: "1rem",
    marginRight: "1rem",
  },
  dashMob: {
    height: "0.75rem",
    marginTop: "1rem",
    marginRight: "1rem",
    txt: "123"
  }
}


export default CooperationInfo
