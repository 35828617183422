import { BrowserRouter } from "react-router-dom"
// Components
import Main from "./components/Main"
import Header from "./components/Header"
import Footer from "./components/Footer"
// Constants
import { color } from "./constants"


function App() {
  return (
    <BrowserRouter>
      <div className="App" style={styles.appStyle}>
        <Header />
        <Main />
        <Footer />
      </div>
    </BrowserRouter>
  )
}


const styles = {
  appStyle: {
    background: color.background.main,
  },
}


export default App
