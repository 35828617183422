import { useState } from "react"

import Button from "react-bootstrap/Button"
import { Link } from "react-router-dom"
// constants
import { color, localPaths } from "../../constants"


function CooperateBtn() {
  const [hovered, setHovered] = useState(false)

  const buttonStyles = {
    backgroundColor: hovered ? color.button.mainHover : color.button.main,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: color.button.border,
    transition: "background-color 0.3s",
  }

  return(
    <Button 
      as={Link}
      style={buttonStyles} 
      size="lg" 
      to={localPaths.cooperation} 
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      UMÓW SIĘ NA SESJĘ
    </Button>
  )
}


export default CooperateBtn
