export const headerHeight = 57
export const footerHeight = 30
export const mobileBrowserHeader = 56


export const localPaths = {
  home: "/",
  policies: "/policies",
  about: "/about",
  contacts: "/contacts",
  cooperation: "/cooperation",
//    blog: "/blog",
//    blog1: "/blog/1",
  }


// export const color = {
//   background: {
//     main: "#f5f5f5",
//     secondary: "#f1eee8",
//     bright: "#bdccfe",
//     bright2: "#e7dbda",
//     header: "#a9c4c9",
//     footer: "#0c6170",
//     newsletterSubscription: "#a9c4c9",
//   },
//   button: {
//     main: "#e77d7d",
//     mainBorder: "#50312f",
//     mainHover: "#e05858",  // used in Button.css
//     subscribe: "#afafaf",
//     sbuscribeBorder: "#ffffff",
//     subscribeHover: "#888888",  // used in Button.css
//   },
//   font: {
//     main: "#0c6170",
//     homeNavigation: "#50312f",
//     footer: "#ffffff",
//   },

// }

export const color = {
  background: {
    main: "#f1eee8",
    secondary: "#fdfcf5",
    bright: "#bdccfe",
    bright2: "#fdfcf5",
    header: "#f1eee8",
    footer: "#05445e",
    newsletterSubscription: "#a85523",
  },
  button: {
    main: "#e77d7d",
    mainBorder: "#50312f",
    mainHover: "#e05858",  // used in Button.css
    subscribe: "#ebba59",
    sbuscribeBorder: "#ffffff",
    subscribeHover: "#05445e",  // used in Button.css
  },
  font: {
    main: "#05445e",
    secondary: "#15392f",
    placeholder: "#ffffff",
    homeNavigation: "#05445e",
    homeNavigationHover: "#ebba59",
    footer: "#fdfcf5",
    newsletterSubscription: "#fdfcf5",
    link: "#fdfcf5",
    linkHover: "#05445e",
  },

}


export const font = {
  main: "Open Sans",
  main2: "DM Sans",
  // constants.js
  MONTSERRAT: "Montserrat",
  KOLLEKTIF: "Kollektif",
  EYESOME_SCRIPT: "Eye Some Script",
  THE_SEASONS_LIGHT: "The Seasons Light",
  SACRAMENTO: "Sacramento",
  MONTSERRAT_CLASSIC: "Montserrat Classic",
  THE_SEASONS: "The Seasons",
  // size
  size: {
    main: "1.3rem",
    small: "0.75rem"
  }
}
