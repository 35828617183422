import { Container, Nav, Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
// constants
import { color, headerHeight, localPaths } from "../constants"


const Header = () => {
  return (
    <header id={"header"} style={styles.headerStyle}>
      <Navbar collapseOnSelect style={styles.navbar} expand="lg">
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarSupportedContent" style={styles.toggle} />
          <Navbar.Collapse id="navbarScroll" style={styles.navbarCollapseCustom}>
            <span style={styles.fillerLeft} />
            <a href={localPaths.home}>
              <img src={"/images/logo.png"} alt="site logo" style={styles.logo} />
            </a>
            <span style={styles.fillerRight} />
            <Nav
              className="me-auto my-2 my-lg-0"
              style={styles.navbar}
              navbarScroll
            >

              <Nav.Link as={Link} style={styles.navLink} className="nav-link active" to={localPaths.home} eventKey="" >Home</Nav.Link>
              <Nav.Link as={Link} style={styles.navLink} className="nav-link active" to={localPaths.about} eventKey="" >O mnie</Nav.Link>
              <Nav.Link as={Link} style={styles.navLink} className="nav-link active" to={localPaths.cooperation} eventKey="" >Usługi</Nav.Link>
              <Nav.Link as={Link} style={styles.navLink} className="nav-link active" to={localPaths.contacts} eventKey="" >Kontakt</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}


const styles = {
  headerStyle: {
    backgroundColor: color.background.header,
    // position: "fixed",
    zIndex: 1000,
    width: "86%",
    height: headerHeight,
    margin: "0 7vw 0 7vw",
    borderBottom: `solid ${color.font.main} 1px`,
  },
  navbar: {
    backgroundColor: color.background.header,
    textAlign: "center",
    margin: "auto",
    zIndex: 100,
    padding: 0,
  },
  toggle:{
    marginTop: 10,
    marginBottom: 10,
  },
  navbarCollapseCustom: {
    backgroundColor: color.background.header,
    marginTop: 5,
    borderRadius: 15,
    opacity: .95,
  },
  logo: {
    height: 40,
    maxInlineSize: "fit-content",
    alignSelf: "center",
  },
  navLink: {
    // textDecoration: "underline",
    fontSize: "1.2rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },
  fillerLeft: {
    height: 0,
    flexGrow: 0.1,
  },
  fillerRight: {
    height: 0,
    flexGrow: 0.4,
  },
}


export default Header
