import { useEffect } from "react"

import HomeNavigation from "./HomeNavigation"
// constants
import { color, font, mobileBrowserHeader } from "../../constants"


function Images() {
  return (
    <div style={styles.content}>
      <img src={"/images/logo.png"} alt="Logo" style={styles.logo} />
      <br/>
      <img src={"/photos/main.webp"} alt="Olena Melentieva" style={styles.image} className="image"/>
    </div>
  )
}


function Home() {
  useEffect(() => {
    document.title = "Olena Melentieva - Coaching"
  }, [])

  return (
    <div>
      <div className="d-none d-lg-block">
        <div style={styles.splitPage} className="splitPage">
          <div style={{ ...styles.column, ...styles.left }} className="column left">
            <Images />
          </div>
          <div style={{ ...styles.column, ...styles.right }} className="column right">
          <div style={styles.content} className="content">
              <HomeNavigation />
            </div>
          </div>
        </div>
      </div>

      <div className="d-lg-none mob" style={styles.mob}>
          <div style={styles.top} className="top">
            <Images />
          </div>
            <div style={styles.bottom} className="content bottom">
              <HomeNavigation />
            </div>
      </div>
    </div>
  );
}


const styles = {
  splitPage: {
    height: "100vh",
    display: "flex"
  },
  column: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  left: {
    width: "calc(7 / 12 * 100%)",
    backgroundColor: color.background.main,
  },
  right: {
    width: "calc(5 / 12 * 100%)",
    backgroundColor: color.background.secondary,
    fontFamily: font.main,
    fontSize: "max(1.95vw, 22px)",
    fontWeight: 400,
  },
  content: {
    maxWidth: "90%",
    textAlign: "center"
  },
  image: {
    maxHeight: "55vh",
    maxWidth: "90vw",
    borderRadius: 10,
  },
  logo: {
    width: "30vw",
    height: "auto",
    paddingTop: 0,
    paddingBottom: "10vh"
  },

  // MOBILE
  mob: {
    height: "100vh",
    backgroundColor: color.background.secondary,
  },
  top: {
    minHeight: `calc(75vh - ${mobileBrowserHeader}px)`,
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color.background.secondary,
  },
  bottom: {
    minHeight: "25vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color.background.secondary,
    fontFamily: font.main,
    fontSize: "max(1.95vw, 22px)",
    fontWeight: 400,
  },
}

export default Home
